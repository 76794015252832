<div class="main-content">
  <div class="row">
    <div>
      <div class="card card-user">
        <div class="card-header">
          <h5 class="card-title">{{ form?.name }}</h5>
        </div>
        <div class="card-body">
          <form (ngSubmit)="submitForm()">
            
            <!-- Display Donation Amounts Based on Type -->
            <div *ngIf="form?.donationType" class="donation-section">
              <h6 class="donation-type-title">Donation Options</h6>

              <!-- Single Donation Amount -->
              <div *ngIf="form.donationType === 'SINGLE'" class="form-group">
                <p>Donation Amount: ${{ form.singleDonationAmount }}</p>
              </div>

              <!-- Multiple Donation Amounts -->
              <div *ngIf="form.donationType === 'MULTIPLE'" class="form-group">
                <label>Choose an Amount</label>
                <div class="btn-group" role="group" aria-label="Donation Amounts">
                  <button *ngFor="let amount of form.multipleDonationAmounts" type="button"
                          class="btn btn-outline-primary"
                          [class.active]="selectedAmount === amount"
                          (click)="selectDonationAmount(amount)">
                    ${{ amount }}
                  </button>
                </div>
              </div>
              

              <!-- Donor Defined Amount -->
              <div *ngIf="form.donationType === 'DONOR_DEFINED'" class="form-group">
                <label for="donorDefinedAmount">Enter Your Donation Amount (USD)</label>
                <input type="number" class="form-control" id="donorDefinedAmount"
                       [(ngModel)]="formData.donorDefinedAmount"
                       name="donorDefinedAmount" placeholder="Enter amount" required>
              </div>
            </div>

            <!-- Dynamic Fields -->
            <div *ngFor="let field of form?.fields" class="form-group">
              <label>{{ field.label }}</label>
              <input *ngIf="field.type === 'text'" type="text" class="form-control" [(ngModel)]="formData[field.label]" name="{{ field.label }}" placeholder="Enter {{ field.label }}" required>
              <input *ngIf="field.type === 'number'" type="number" class="form-control" [(ngModel)]="formData[field.label]" name="{{ field.label }}" placeholder="Enter {{ field.label }}">
              <input *ngIf="field.type === 'email'" type="email" class="form-control" [(ngModel)]="formData[field.label]" name="{{ field.label }}" placeholder="Enter {{ field.label }}">
              <input *ngIf="field.type === 'checkbox'" type="checkbox" [(ngModel)]="formData[field.label]" name="{{ field.label }}">
            </div>

            <div class="row">
              <div class="update ml-auto mr-auto d-flex justify-content-end">
                <button type="submit" class="btn btn-primary btn-round">
                  Submit Donation
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
