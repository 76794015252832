import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-form-success',
  templateUrl: './form-success.component.html',
  styleUrls: ['./form-success.component.scss']
})
export class FormSuccessComponent implements OnInit {
  formId: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit() {
    // Retrieve the formId from the route parameters
    this.formId = this.route.snapshot.paramMap.get('id');

    // Set the page title to include the form name if needed
    this.titleService.setTitle('GiftAllie - Success');

    // Additional logic if needed
  }
}
