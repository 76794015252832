<nav id="home-header" [ngClass]="{ 'header-fixed': isFixed }" class="navbar navbar-expand-lg">
  <div class="container d-flex justify-content-between align-items-center">
    <a class="navbar-brand" routerLink="/">
      <span id="logo-text">GiftAllie</span>
    </a>
    <button class="c-btn-menu" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
      <div class="relative">
        <span class="bar bar1" [ngClass]="{ 'open': !isCollapsed }"></span>
        <span class="bar bar2" [ngClass]="{ 'open': !isCollapsed }"></span>
        <span class="bar bar3" [ngClass]="{ 'open': !isCollapsed }"></span>
        <span class="sr-only">Menu</span>
      </div>
    </button>
    <div class="collapse navbar-collapse" id="collapseExample" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item" (click)="navigateAndScroll('/', 'intro-wrap')">
          <a class="nav-link" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'services-wrap')">
          <a class="nav-link">Services</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'pricing-wrap')">
          <a class="nav-link">Pricing</a>
        </li>
        <li class="nav-item" (click)="navigateAndScroll('/', 'contacts-wrap')">
          <a class="nav-link">Contact Us</a>
        </li>
        <li *ngIf="!isLoggedIn" class="nav-item" (click)="openLogin()">
          <a class="nav-link">Log In | Sign Up</a>
        </li>
        <li *ngIf="isLoggedIn" class="nav-item" (click)="openDashboard()">
          <a class="nav-link">Dashboard</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
