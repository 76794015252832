export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBhRXaEzqrTip51NvYoJ-SVUeafkueYrh8",
    authDomain: "giftallie-8c3e2.firebaseapp.com",
    projectId: "giftallie-8c3e2",
    storageBucket: "giftallie-8c3e2.appspot.com",
    messagingSenderId: "100940635537",
    appId: "1:100940635537:web:6cb1b9d3332f1daa1d4e30",
    measurementId: "G-DCCDR6CY6T"
  },
  API_BASE_URL: "https://giftallie-api-dev.gunveernatt.uk/api"
};

