import {LandingLayoutComponent} from "./shared/components/layouts/landing-layout/landing-layout.component";
import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";

import {AuthLayoutComponent} from "./shared/components/layouts/auth-layout/auth-layout.component";
import { FormViewComponent } from "./features/dashboard/form-view/form-view.component";
import { FormSuccessComponent } from "./features/dashboard/form-success/form-success.component";
import { DashboardLayoutComponent } from "./shared/components/layouts/dashboard-layout/dashboard-layout.component";
import { LoadingComponent } from "./features/dashboard/loading/loading.component";

import { AuthGuard } from "./core/guard/auth.guard";
import { FormHostComponent } from "./features/dashboard/form-host/form-host.component";

const routes: Routes = [
  {
    path: '',
    component: LandingLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/landing/landing.module').then(m => m.LandingModule),
      },
    ],
  },

  {
    path: "",
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "app/:orgId",
        loadChildren: () =>
          import("./features/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },

  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "sessions",
        loadChildren: () =>
          import("./features/dashboard/sessions.module").then(
            (m) => m.SessionsModule
          ),
      },
    ],
  },
  { path: 'loading', component: LoadingComponent, canActivate: [AuthGuard]  },
  { path: 'org/:orgId/form/:id', component: FormHostComponent },
  { path: 'org/:orgId/form/:id/success', component: FormSuccessComponent },

  // Add a wildcard route to handle undefined paths and redirect to the root
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
