import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';

// Define interfaces for the response data models
export interface Organization {
  id: string | null;
  name: string | null;
  domain: string | null;
}

interface UserProfileResponse {
  email: string | null;
  organization: Organization | null;
  role: string;
  error: string | null;
}

export interface UserOrganizationsResponse {
  organizations: Organization[] | null;
  error: string | null;
}

// Define your request and response interfaces
interface RegisterOrganizationRequest {
  name: string;
  domain: string;
}

interface AddUserRequest {
  email: string;
  role: string;
  // Add other necessary fields
}

interface AssignRoleRequest {
  role: string;
}

interface InviteUserRequest {
  email: string;
  // Add other necessary fields
}

interface UpdateUserRequest {
  name?: string;
  role?: string;
  // Add other optional fields for updates
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.API_BASE_URL;
  
  private userProfileSubject = new BehaviorSubject<any>(null); // Initialize the BehaviorSubject
  public userProfile$: Observable<any> = this.userProfileSubject.asObservable(); // Observable to be used by other components

  constructor(private http: HttpClient) {}

  loadUserProfile(orgId: string) {
    // Call this method to trigger the API call and share the data across components
    this.getUserProfile(orgId).subscribe();
  }

  // Register organization and create admin
  registerOrganization(orgData: RegisterOrganizationRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/register-organization`, orgData);
  }

  // Add user to organization
  addUserToOrganization(userId: string, userData: AddUserRequest, orgId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${orgId}/organization/${userId}/addUser`, userData);
  }

  // Assign role to user
  assignRole(userId: string, roleData: AssignRoleRequest, orgId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${orgId}/user/${userId}/assignRole`, roleData);
  }

  // Get users for organization
  getUsersForOrganization(orgId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${orgId}/organization/users`);
  }

  // Invite user to organization
  inviteUser(inviteData: InviteUserRequest, orgId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${orgId}/invite-user`, inviteData);
  }

  // Update user information
  updateUser(userId: string, updateData: UpdateUserRequest, orgId: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${orgId}/user/${userId}`, updateData);
  }

  // Delete user from organization
  deleteUser(userEmail: string, orgId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${orgId}/organization/user/${userEmail}`);
  }

  // Get user profile with the expected response structure
  getUserProfile(orgId: string): Observable<UserProfileResponse> {
    return this.http.get<UserProfileResponse>(`${this.baseUrl}/${orgId}/user/profile`).pipe(
      tap((profile) => this.userProfileSubject.next(profile)) // Update the BehaviorSubject with the fetched profile
    );
  }

    // Get user profile with the expected response structure
  getUserOrgs(): Observable<UserOrganizationsResponse> {
    return this.http.get<UserOrganizationsResponse>(`${this.baseUrl}/user/orgs`).pipe(
      tap((profile) => this.userProfileSubject.next(profile)) // Update the BehaviorSubject with the fetched profile
    );
  }
  
}
