import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UserService } from '@app/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  orgId: string | null = null;
  private paramSubscription: Subscription | undefined;

  constructor(
    private userService: UserService, 
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramSubscription = this.route.firstChild?.paramMap.subscribe((params: ParamMap) => {
      this.orgId = params.get('orgId');
      if (this.orgId) {
        // Do any necessary operations with the orgId here
        localStorage.setItem('currentOrgId', this.orgId);
        this.userService.loadUserProfile(this.orgId);
      }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }


    // Helper method to get the current orgId (can be used in other components)
    static getCurrentOrgId(): string | null {
      return localStorage.getItem('currentOrgId');
    }

}