import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Organization, UserOrganizationsResponse, UserService } from '@app/services/user/user.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  loading = true;
  message: string | null = null;
  organizations: Organization[] | null = null;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.userService.getUserOrgs().subscribe({
      next: (response: UserOrganizationsResponse) => {
        this.loading = false;
        if (response.organizations === null || response.organizations.length === 0) {
          this.message = 'No organizations found.';
        } else {
          // Store organizations in browser storage
          localStorage.setItem('userOrganizations', JSON.stringify(response.organizations));
          
          if (response.organizations.length === 1) {
            const orgId = response.organizations[0].id;
            this.router.navigate([`/app/${orgId}/overview`]);
          } else {
            this.organizations = response.organizations;
          }
        }
      },
      error: (err) => {
        this.loading = false;
        this.message = 'An error occurred while fetching organizations.';
        console.error('Error fetching organizations:', err);
      }
    });
  }

  onSelectOrg(orgId: string | null): void {
    if (orgId) {
      this.router.navigate([`/app/${orgId}/overview`]);
    }
  }
}