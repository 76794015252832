import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, ComponentFactoryResolver, ViewContainerRef, ComponentRef, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormViewComponent } from '@app/features/dashboard/form-view/form-view.component';
import { Form, PublicFormsService } from '@app/services/public-form/public-form.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-form-host',
  template: `
    <div [innerHTML]="sanitizedHtml" #container></div>
  `,
})
export class FormHostComponent implements OnInit, OnChanges {
  @Input() form: Form;
  @Input() templateHtml: string = '';
  sanitizedHtml: SafeHtml;
  @ViewChild('container', { read: ElementRef }) container: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private formsService: PublicFormsService,
    private sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    // Fetch form data if `form` input is null
    if (!this.form) {
      const formId = this.route.snapshot.paramMap.get('id');
      const orgId = this.route.snapshot.paramMap.get('orgId');

      if (formId) {
        this.formsService.getForm(formId, orgId).subscribe(
          formResponse => {
            this.form = formResponse.form;
            this.templateHtml = formResponse.template.html
            // Initialize formData or other properties if needed
            this.loadTemplate(); // Load template after form is fetched
          },
          error => {
            console.error('Error fetching form:', error);
          }
        );
      } else {
        this.loadTemplate(); // Load template even if formId is not available
      }
    } else {
      this.loadTemplate(); // Load template if form is already provided
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['templateHtml'] || changes['form']) {
      this.loadTemplate(); // Reload template and embed component when inputs change
    }
  }

  loadTemplate() {
    // Use templateHtml directly if available
    if (this.templateHtml) {
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(this.templateHtml);
        setTimeout(() => this.embedComponent(), 0);
      } else {
        // Fetch template from service if templateHtml is not provided
        // this.formsService.getTemplate('1').subscribe(template => {
        //   const html = template.html;
        //   this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(html);
        //   setTimeout(() => this.embedComponent(), 0);
        // });
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(`
          <div id="form-placeholder"></div>
      `)
      setTimeout(() => this.embedComponent(), 0);
      }
  }

  embedComponent() {
    const placeholder = this.container.nativeElement.querySelector('#form-placeholder');
    if (placeholder) {
      // Clear existing components
      this.viewContainerRef.clear();

      const factory = this.resolver.resolveComponentFactory(FormViewComponent);
      const componentRef: ComponentRef<FormViewComponent> = this.viewContainerRef.createComponent(factory);

      // Pass data to the dynamic component
      componentRef.instance.form = this.form;

      // Append the dynamically created component to the placeholder
      placeholder.appendChild(componentRef.location.nativeElement);
    }
  }
}
