import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { auth } from '@app/firebase-initialization';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          let orgId = this.getOrgIdFromRoute(route);
          console.log('canActivate', orgId)
          if (orgId) {
            if (this.userHasAccessToOrg(orgId)) {
              resolve(true);
            } else {
              this.router.navigate(['/loading']);
              resolve(false);
            }
          } else {
            resolve(true);
          }
        } else {
          this.router.navigate(['/sessions/signin']);
          resolve(false);
        }
      });
    });
  }

  private getOrgIdFromRoute(route: ActivatedRouteSnapshot): string | null {
    if (route.paramMap.has('orgId')) {
      return route.paramMap.get('orgId');
    }
    
    // If not found in the current route, check child routes
    if (route.firstChild) {
      return this.getOrgIdFromRoute(route.firstChild);
    }
    
    return null;
  }

  private userHasAccessToOrg(orgId: string): boolean {
    const storedOrgs = localStorage.getItem('userOrganizations');
    if (storedOrgs) {
      const organizations: Organization[] = JSON.parse(storedOrgs);
      return organizations.some(org => org.id === orgId);
    }
    return false;
  }
}

interface Organization {
  id: string;
  // Add other properties as needed
}