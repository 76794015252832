import { LandingLayoutComponent } from './components/layouts/landing-layout/landing-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';



import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';



import { ScrollToDirective } from './directives/scroll-to.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from '@app/features/landing/components/header/header.component';
import { FormViewComponent } from '@app/features/dashboard/form-view/form-view.component';
import { FormHostComponent } from '@app/features/dashboard/form-host/form-host.component';
import { FormsModule } from '@angular/forms';


const exportedClasses = [
  AuthLayoutComponent,
  ScrollToDirective,
  LandingLayoutComponent,
  HeaderComponent,
  FormViewComponent,
  FormHostComponent,
];


@NgModule({
  imports: [CommonModule, NgbModule, RouterModule, FormsModule],
  declarations: exportedClasses,
  exports: exportedClasses
})
export class SharedModule {}
