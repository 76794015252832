<div *ngIf="loading" class="loading-container">
    <mat-spinner></mat-spinner>
    <p>Loading...</p>
  </div>
  
  <div *ngIf="!loading" class="selection-container">
    <div *ngIf="message" class="message">{{ message }}</div>
    
    <div *ngIf="organizations && organizations.length > 0" class="org-selection">
      <h2>Select an Organization</h2>
      <div class="org-list">
        <div *ngFor="let org of organizations" (click)="onSelectOrg(org.id)" class="org-card">
          <h3>{{ org.name }}</h3>
          <p>Domain: {{ org.domain }}</p>
        </div>
      </div>
    </div>
  </div>
  