import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicStripeService {
  private baseUrl = environment.API_BASE_URL + '/public'
  
  constructor(private http: HttpClient) {}

  createPaymentSession(data: {
    amount: number;
    currency: string;
    productName: string;
    successUrl: string;
    formData: any;  // Add formData to the data object
  }): Observable<any> {
    return this.http.post(`${this.baseUrl}/create_payment_session`, data);
  }
}
