import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Form, PublicFormsService } from '@app/services/public-form/public-form.service';
import { PublicStripeService } from '@app/services/public-stripe/public-stripe.service';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent implements OnInit {
  @Input() form: Form;
  formData: any = {};
  selectedAmount: number | null = null;
  formId: string | null = null;
  orgId: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stripeService: PublicStripeService,
    private titleService: Title,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.formId = this.route.snapshot.paramMap.get('id');
    this.orgId = this.route.snapshot.paramMap.get('orgId');
    this.titleService.setTitle('GiftAllie - ' +  this.form.name);

      // Initialize formData with default values based on form fields if needed
      this.initializeFormData();
  }

  initializeFormData() {
    if (this.form?.fields) {
      this.form.fields.forEach(field => {
        if (!this.formData[field.label]) {
          // Set default values for each field based on type
          if (field.type === 'checkbox') {
            this.formData[field.label] = false; // Default for checkboxes
          } else {
            this.formData[field.label] = ''; // Default for other fields
          }
        }
      });
    }
  }

  selectDonationAmount(amount: number) {
    this.selectedAmount = amount;
  }

  submitForm() {
    let amount: number | null = null;
  
    switch (this.form.donationType) {
      case 'SINGLE':
        amount = this.form.singleDonationAmount;
        break;
      case 'MULTIPLE':
        amount = this.selectedAmount;  // The user selected amount
        break;
      case 'DONOR_DEFINED':
        amount = this.formData.donorDefinedAmount;  // The user entered amount
        break;
    }
  
    if (!amount) {
      console.error('No donation amount selected');
      return;
    }
  
  // Get the full URL of the current page
  const currentUrl = this.router.url;
  // Construct the success URL by appending '/success' to the current path
  const successUrl = `${window.location.origin}${currentUrl}/success`;
  console.log('Form Data:', this.formData);  // Log formData to see the values

    // Create payment session when the form is submitted
    const paymentSessionData = {
      orgId: this.orgId,
      formId: this.formId,
      amount: amount * 100,  // Assuming the amount is in dollars and Stripe expects cents
      currency: 'USD',
      productName: this.form.name || 'Donation',
      successUrl: successUrl,  // Replace with your success URL
      formData: this.formData  // Include the dynamically captured form data
    };
  
    this.stripeService.createPaymentSession(paymentSessionData).subscribe(
      response => {
        if (response && response.url) {
          window.location.href = response.url;
        } else {
          console.error('Invalid response from payment session creation');
        }
      },
      error => {
        console.error('Error creating payment session:', error);
      }
    );
  }
  
}
