import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';

export interface FormField {
  label: string;
  type: string;
}

export interface FormResponse {
  id: string;
  form: Form
  template: Template
}

export interface Template {
  name: string;
  html: string;
}

export interface Form {
  name?: string;
  fields: FormField[]; // Updated from object to array
  userId: string;
  organizationId: string;
  donationType?: DonationType;
  multipleDonationAmounts: number[];
  singleDonationAmount?: number;
  templateId: string
  error?: string;
}

export enum DonationType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  DONOR_DEFINED = 'DONOR_DEFINED'
}

@Injectable({
  providedIn: 'root',
})
export class PublicFormsService {
  private apiUrl = environment.API_BASE_URL + '/public'

  constructor(private http: HttpClient) {}

  // Get Form by ID
  getForm(id: string, orgId: string): Observable<FormResponse> {
    return this.http.get<FormResponse>(`${this.apiUrl}/${orgId}/form/${id}`).pipe(
      map((response: FormResponse) => response),
      catchError(this.handleError)
    );
  }

  // Error Handling
  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(() => error);
  }
}
